<template>
  <div class="col-md-12 showing">
    <p>
      <small>Results for <mark class="fw-bold fst-italic small">{{ searchInfo }}</mark> around <b>{{
          u_values.currentLocation }}</b>
      </small>

      <small v-if="u_values.nearbyBtnIsClicked && (u_values.storeListBackUp.length > 0)" class="float-end fw-bold">
        <a @click.prevent="u_methods.returnStoreBackUp()" href="#" style="color: #0085a1;">
          <i class="bi bi-arrow-down-circle-fill"></i> show within my area
        </a>
      </small>
    </p>
  </div>

  <!-- <div class="col-md-12">
    <div style="margin-top: 17px;" class="d-flex">
      <div class="col-md-1 mt-1"><b>filter by:</b> </div>
      <div class="col-md-2 ms-3">
        <select class="form-select sorter form-select-sm" name="" id="">
          <option selected>none</option>
          <option value="open">open</option>
          <option value="closed">closed</option>
        </select>
      </div>
    </div>
  </div> -->

  <div v-once v-show="prescStoresExists()" style="margin-top: 17px;">
    <div class="small">
      <i class="bi bi-exclamation-circle-fill"></i> :
      prescription required drugs.
    </div>
  </div>


  <div v-for="pharm in u_values.storeList" :key="pharm.StoreId" class="mt-3">
    <div class="card storeCard shadow-sm">
      <div class="card storeDetailsCard">
        <div class="card-body p-2">
          <span class="float-end km-text text-muted"><i class="bi bi-compass"></i> {{pharm.distance.toFixed(1)}}
            km</span>

          <span class="fs-5 fw-bold" style="color: #0085a1;"> {{ u_methods.FirstLetterCaps(pharm.StoreName)}}</span>
          <br>

          <span class="small text-muted">{{ pharm.StoreAddress }}. </span> <br>
          <div class="col-md-12 mt-3">
            <div class="d-flex">
              <div class="col-6">
                <div @click="getStoreTimes(pharm.StoreId)" class="small dropdown">
                  <span v-if="opDisplay(pharm.StoreId) == 'open'" class="openIndicator dropdown-toggle"
                    id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><i
                      class="bi bi-check-circle-fill"></i> {{opDisplay(pharm.StoreId)}}
                    now
                  </span>
                  <span v-else-if="opDisplay(pharm.StoreId) == 'closed'" class="closedIndicator dropdown-toggle"
                    id="dropdownMenuButton1" data-bs-toggle="dropdown"
                    aria-expanded="false">{{opDisplay(pharm.StoreId)}}
                  </span>
                  <span v-else>

                  </span>

                  <table class="dropdown-menu table-sm p-2 pt-0" aria-labelledby="dropdownMenuButton1"
                    style="width: 250px;">
                    <tbody>
                      <tr>
                        <th colspan="3" class="text-center fw-bold" style="font-size: 14px; color: #053a46;">Opening
                          Hours
                        </th>
                      </tr>
                      <tr style="font-size: 12px;" v-for="(el, index) in thisStoreTimes" :key="index">
                        <th :class="{isToday: isToday(el.Day)}">{{el.Day}}</th>
                        <td :class="{isToday: isToday(el.Day)}">{{el.Open}} - {{el.Close}}</td>
                        <td v-if="isToday(el.Day)" style="color: #0085a1" class="small"><i
                            class="bi bi-circle-fill"></i>
                        </td>
                        <th v-else></th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-6">
                <span class="float-end">
                  <small class="fst-italic">
                    <span v-show="pharm.DeliveryAvaliable" class="deliveryBadge rounded-pill p-1 px-2"><i
                        class="bi bi-check-circle"></i> Delivery available
                    </span>&nbsp;
                  </small>

                </span>
              </div>
            </div>
          </div>

        </div>

      </div>
      <div class="card-body pt-0">
        <div class="post-preview card-text">
          <!-- <small style="color: #af8012"><i> updated {{ getHumanDate(pharm.LastUpdatedDate)
                }}</i></small> -->


          <p class="post-meta mt-3">
            <span v-for="(drug, index) in getFirst2(getDrugsWithThisId(pharm.StoreId))" :key="index"
              class="text-lowercase badge text-dark">
              <mark class="p-1 fw-normal small fw-bold">{{ drug.ItemName }}</mark>
              <button @click.prevent class="mx-1 border-0" v-show="drug.IsPrescription" data-bs-toggle="tooltip"
                data-bs-placement="top" title="prescription required">
                <i class="bi bi-exclamation-circle-fill"></i>
              </button>
              <span v-show="drug.Price" class="fw-bold">
                - &#8358;{{ u_methods.numberWithCommas(drug.Price) }} </span>&nbsp;
              <span v-if="drug.IsPrescription">
                <a :href="'https://api.whatsapp.com/send?phone='+pharm.StoreWhatappPhone+'&text='+whatsAppTextForPrescription(pharm.StoreName, drug.ItemName, drug.Price)"
                  class="orderBtn">
                  <i class="bi bi-whatsapp"></i> chat</a>
                <!-- <span class="mx-1 prescriptionP"><i class="bi bi-exclamation-circle-fill"></i></span> -->
              </span>
              <span v-else>
                <span v-if="drug.ItemPublishFlag">
                  <a @click.prevent="visitOrdering(pharm.StoreId, drug.Price, drug.ItemId)" href="" class="orderBtn">
                    <i class="bi bi-cart-check"></i> Order</a>
                </span>
              </span>
            </span>

            <span v-if="getDrugsWithThisId(pharm.StoreId).length > 2" class="dropdown">
              <span class="dropdown-toggle moreBtn" id="dropdownMenuButton2" data-bs-toggle="dropdown"
                aria-expanded="false" style="font-size: 15px;">
                {{getDrugsWithThisId(pharm.StoreId).length-2}} more
                result{{u_methods.plural(getDrugsWithThisId(pharm.StoreId).length-2)}}</span>

              <ul class="dropdown-menu p-2 py-3 overflow-auto" aria-labelledby="dropdownMenuButton2"
                style="max-height: 250px;">
                <li v-for="(drug, index) in getDrugsWithThisId(pharm.StoreId).slice(2, pharm.medStoresItems.length)"
                  :key="index" class="text-lowercase badge text-dark">
                  <span class="dropdown-ite my-5">
                    <mark class="p-1 fw-normal small fw-bold">{{ drug.ItemName }}</mark>
                    <span v-show="drug.IsPrescription" class="mx-1"><i class="bi bi-exclamation-circle-fill"></i></span>
                    <span v-show="drug.Price" class="fw-bold">
                      - &#8358;{{ u_methods.numberWithCommas(drug.Price) }} </span>&nbsp;
                    <span v-if="drug.IsPrescription">
                      <a :href="'https://api.whatsapp.com/send?phone='+pharm.StoreWhatappPhone+'&text='+whatsAppTextForPrescription(pharm.StoreName, drug.ItemName, drug.Price)"
                        class="orderBtn">
                        <i class="bi bi-whatsapp"></i> chat</a>
                    </span>
                    <span v-else>
                      <span v-if="drug.ItemPublishFlag">
                        <a @click.prevent="visitOrdering(pharm.StoreId, drug.Price, drug.ItemId)" href=""
                          class="orderBtn">
                          <i class="bi bi-cart-check"></i> Order</a>
                      </span>
                    </span>
                  </span>

                </li>

              </ul>
            </span>
          </p>
          <div>

            <!-- buttons################################### -->
            <span class="float-end mt-2">
              <a v-show="pharm.StoreWhatappPhone"
                :href="'https://api.whatsapp.com/send?phone='+pharm.StoreWhatappPhone+'&text='+whatsAppTextToStore(pharm.StoreName)"
                class="whatsappBtn small fw-bold">
                <small><i class="bi small bi-whatsapp"></i> chat</small>
              </a>
              <!-- <a v-show="!pharm.StoreWhatappPhone" @click.prevent href="" class="whatsappBtn small fw-bold">
                <small><i class="bi small bi-whatsapp"></i> chat</small>
              </a> -->
              <a v-show="pharm.PhoneNumber" :href="'tel:' + pharm.PhoneNumber" class="callBtn small fw-bold">
                <small><i class="bi small bi-telephone-fill"></i> call</small>
              </a>
              <span @click="sharePharm(pharm.StoreId)" class="shareBtn small fw-bold">
                <small><i class="bi small bi-share-fill"></i> share</small>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!-- <div v-for="(table,index) in list" :key="index">
        {{table}}
    </div>

    <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-end">
            <li class="page-item disabled">
                <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
            </li>
            <li v-for="(page, index) in pageNum" :key="index" class="page-item">
                <a @click.prevent="changePage(index+1)" class="page-link" href="#">{{ index+1 }}</a>
            </li>

            <li class="page-item">
                <a @click.prevent="NextNum" class="page-link" href="#">Next</a>
            </li>
        </ul>
    </nav> -->
</template>

<script>
  import { ref, onUpdated, inject, watch, onMounted } from "vue";
  import moment from "moment";
  import axios from "axios";
  import { Tooltip } from 'bootstrap/dist/js/bootstrap.min.js'

  export default {
    name: "StoreListComponent",
    emits: ["hideReturnBtn"],
    props: {
      searchedItem: String,
    },
    setup(props, context) {
      const codeStore = inject('codeStore')
      const baseURL = codeStore.constants.baseURL
      const u_methods = codeStore.methods
      const u_values = codeStore.values

      const pharms = ref(u_values.storeList)

      onMounted(() => {
        u_values.nearbyBtnIsClicked = false
        removeUnsearchableDrugs(u_values.storeList)
        u_methods.updateIDArray()
        loadStoreTimes()
        // console.log(u_values.storeList, 'main');

        // Array.from(document.querySelectorAll('button[data-bs-toggle="tooltip"]'))
        //   .forEach(tooltipNode => new Tooltip(tooltipNode))
      })


      // return num of stores drugs that have prescription
      function prescStoresExists() {
        let drugs = []
        u_values.storeList.forEach(store => {
          store.medStoresItems.forEach(item => {
            if (item.IsSearchable) {
              drugs.push(item);
            }
          });
        });
        let pPharms = drugs.filter(x => x.IsPrescription == true)
        return (pPharms.length > 0) ? true : false

      }

      const searchInfo = ref(props.searchedItem);

      watch(() => u_values.storeList, () => {
        // pharms.value = u_values.storeList
        u_methods.updateIDArray()
        loadStoreTimes()
      })

      const drugsFiltered = ref([])
      function removeUnsearchableDrugs(pharms) {
        drugsFiltered.value = []
        pharms.forEach(store => {
          store.medStoresItems.forEach(item => {
            if (item.IsSearchable) {
              drugsFiltered.value.push(item);
            }
          });
        });
      }

      const getDrugsWithThisId = (id) => {
        return drugsFiltered.value.filter(x => x.StoreId == id)
      }

      function sharePharm(id) {
        let pharm = u_values.storeList.find(x => x.StoreId = id)
        u_methods.shareStoreData(pharm)
      }

      function getHumanDate(date) {
        return moment(date).fromNow();
      }



      const opDisplay = (id) => {
        // enable tootip
        Array.from(document.querySelectorAll('button[data-bs-toggle="tooltip"]'))
          .forEach(tooltipNode => new Tooltip(tooltipNode))
        var stringToReturn = "none"
        let arr = u_values.timeArray.find(x => x.StoreId == id)

        if (arr) {
          let ifAnyDayIsActive = arr.DayOfActivitys.some(x => x.active == true)
          if (ifAnyDayIsActive) {
            let dateString = u_values.serverDateString.split(',')[0].toLocaleLowerCase()
            var times = arr.DayOfActivitys.find(x => x.Day.toLocaleLowerCase() == dateString)
            if (times.active) {
              let openTime = moment(times.Open, 'h:mma')
              let closeTime = moment(times.Close, 'h:mma')
              let serverTime = moment(u_values.serverTimeString, 'h:mma')
              let storeIsOpen = openTime.isBefore(serverTime) && serverTime.isBefore(closeTime)
              stringToReturn = storeIsOpen == true ? 'open' : 'closed'
            }
            else stringToReturn = 'closed'
          }
          else {
            // stringToReturn = null
          }

        }

        return stringToReturn
      }

      const thisStoreTimes = ref([])
      function getStoreTimes(id) {
        let arr = u_values.timeArray.find(x => x.StoreId == id)
        thisStoreTimes.value = arr.DayOfActivitys.filter(x => x.active == true)
      }

      async function loadStoreTimes() {
        const idArrays = u_values.storeIdsArray
        const APIstring = baseURL + "MedStoresApi/GetDaysOfActivitiesMulitId"
        try {
          var { data } = await axios.post(APIstring, idArrays);
          if (data.MedicalStoreResponesStatus == 200) {
            u_values.timeArray = data.MedicalStoreResult.AllStoreAvailbilityDays
            u_values.serverDateString = data.MedicalStoreResult.ServerDateString
            u_values.serverTimeString = data.MedicalStoreResult.ServerTimeString
          }
        } catch (error) {
        }
      }

      const isToday = (day) => {
        let dateString = u_values.serverDateString.split(',')[0].toLocaleLowerCase()
        return (day.toLocaleLowerCase() == dateString) ? true : false
      }

      const getFirst2 = (arrayElements) => {
        var newArray = []
        if (arrayElements.length >= 2) {
          for (let index = 0; index < 2; index++) {
            newArray.push(arrayElements[index])
          }
        }

        return newArray.length ? newArray : arrayElements
      }

      const whatsAppTextToStore = (pharm) => {
        let text = 'Hello, ' + pharm + ', I searched for ' + searchInfo.value + ' on allmedstores.com and found your store. I will like to make enquires on ___'
        return text
      }

      const whatsAppTextForPrescription = (pharm, drugName, drugPrice) => {
        let text = 'Hello, ' + pharm + ', I searched for ' + drugName + ' on allmedstores.com and found it on your store as a prescription required drug for N' + drugPrice
        return text
      }

      function visitOrdering(StoreID, drugPrice, drugId) {
        window.location = 'https://store.matsmartshop.com/online-store/item_shared_detail.php?t=' + drugId + '&p=' + drugPrice + '&s=' + StoreID
      }

      // const testArray = ref(['11', '22', '33', '44', '55', '66', '77', '88', '99', '45'])
      // const pageNum = ref([])
      // const list = ref([])
      // const currentNum = ref(1)

      // function changePage(pageNumer) {
      //     list.value = testArray.value.slice(2 * (pageNumer - 1), pageNumer * 2)
      //     currentNum.value = pageNumer
      //     console.log(list.value)
      //     console.log(testArray.value)
      //     console.log(pageNumer)
      //     console.log(pageNum.value)
      // }

      // function NextNum() {
      //     currentNum.value = currentNum.value + 1
      //     changePage(currentNum.value)
      // }

      // onMounted(() => {
      //     list.value = testArray.value.slice(0, 2)
      //     pageNum.value = Array.from({ length: testArray.value.length / 2 }, (_, i) => i + 1)
      //     console.log(pageNum.value);
      //     console.log(list.value)
      // })


      return {
        //pageNum, list, changePage, NextNum
        getHumanDate: getHumanDate,
        sharePharm: sharePharm,
        searchInfo: searchInfo,
        pharms, u_values, u_methods,
        opDisplay: opDisplay,
        getStoreTimes, thisStoreTimes,
        isToday, getFirst2,
        whatsAppTextToStore,
        whatsAppTextForPrescription,
        getDrugsWithThisId,
        visitOrdering,
        prescStoresExists
      };
    },
  };
</script>

<style scoped src="@/assets/css/searchResults.css"></style>
<style scoped>
  .shareBtn {
    min-width: 1.8em;
    padding: 0.2em 0.6em !important;
    margin-left: 0.5em;
    margin-right: 0em;
    background-color: none;
    color: #0085a1 !important;
    cursor: pointer;
    border-radius: 5px;
  }

  .shareBtn:hover {
    background-color: #55def7;
  }

  .callBtn {
    min-width: 1.8em;
    padding: 0.2em 1em !important;
    margin-left: 0.1em;
    background-color: none;
    color: #af8012 !important;
    cursor: pointer;
    border-radius: 5px;
  }

  .callBtn:hover {
    background-color: #f7dca2;
  }

  .whatsappBtn {
    min-width: 1.8em;
    padding: 0.2em 1em !important;
    margin-right: 0.5em;
    background-color: none;
    color: #1dbe1d !important;
    cursor: pointer;
    border-radius: 5px;
  }

  .whatsappBtn:hover {
    background-color: #aaf1aa;
  }

  .orderBtn {
    padding: 0.1em 0.6em !important;
    background-color: #cfeecf;
    color: #1dbe1d !important;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
  }

  .orderBtn:hover {
    background-color: #8ce98c;
  }

  .storeCard {
    border: none;
    border-radius: 20px;
    /* border-left: 3px solid #edad18; */
  }

  .storeDetailsCard {
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    background: #faf8f4;
    border: none;
    border-bottom: #f8e8d8 1px solid;
    padding: 8px;

  }

  .badge {
    margin-right: 14px;
    margin-bottom: 6px;
    font-size: 13px;
    background-color: #f3f1f1;
    padding: 10px 8px;
    /* border-radius: 20px; */
  }

  .deliveryBadge {
    /* font-size: 12px; */
    background-color: #f7eac9;
    /* background-color: #fce8b6; */
    border-radius: 20px;
    /* font-weight: bold; */
  }

  .showing {
    margin-top: 16px;
    margin-bottom: 21px;
  }

  @media screen and (max-width: 767px) {
    /* .badge {
      font-size: 11px;
    } */

    .deliveryBadge {
      font-size: 12px;
    }
  }

  .openIndicator {
    background-color: #e5f4f7;
    color: #0c6577;
    cursor: pointer;
    padding: 1px 16px;
    border-radius: 20px;
    border: 1px #92e9fa solid;
  }

  .openIndicator:hover {
    background-color: #c9eff7;
  }

  .closedIndicator {
    background-color: rgb(223, 219, 219);
    color: rgb(83, 79, 79);
    cursor: pointer;
    padding: 1px 31px;
    border-radius: 20px;
    border: 1px rgb(190, 189, 189) solid;
  }

  .closedIndicator:hover {
    background-color: rgb(248, 226, 226);
  }

  .isToday {
    color: #0085a1;
  }

  .km-text {
    font-size: 13px;
    font-weight: bold;
  }

  .moreBtn {
    color: #0085a1;
    margin-left: 4px;
    cursor: pointer;
    font-style: normal;
  }

  .overflow-auto::-webkit-scrollbar {
    width: 20px;
  }

  .overflow-auto::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 20px;
    border: 8px solid transparent;
    background-clip: content-box;
  }

  .overflow-auto::-webkit-scrollbar-track {
    background-color: white;
  }

  .bi-exclamation-circle-fill {
    color: rgb(153, 6, 6)
  }
</style>