<template>
  <div class="card border-0 shadow-sm col-md-12 hover mt-2">
    <div class="card-body">
      <h5 class="card-header bg-white fs-6 fw-bolder">
        <span v-if="nearByAreaNames.length">We found it in {{nearByAreaNames.length}} nearby
          area{{add_S(nearByAreaNames.length)}}:</span>
        <span v-else>Nearby Areas:</span>
      </h5>

      <div v-if="stillLoading" class="d-flex justify-content-center m-4">
        <PleaseWaitComponent text="Loading data.." />
      </div>

      <div v-else>
        <div v-if="errorOccoured" class="alert alert-light text-danger">
          <i class="bi bi-x-octagon"></i>&nbsp;Sorry, error occoured.
        </div>
        <div v-else>
          <div v-if="u_values.nearByStoresArray.length" class="d-grid overflow-scroll" style="max-height: 230px;">
            <a @click.prevent="showNearByStores(areaName)" v-for="(areaName, index) in nearByAreaNames" :key="index"
              href="#" class="nearList small">
              <div> <span class="text-dark fw-bold">{{index+1}}.</span>&nbsp;
                {{ areaName }} ({{numOfStoresInThisArea(areaName)}}
                store{{add_S(numOfStoresInThisArea(areaName))}}).
              </div>
            </a>
          </div>

          <div v-else class="alert alert-light small text-muted">
            <i class="bi bi-file-earmark-x"></i>&nbsp;No stores.
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card border-0 shadow-sm mt-4 col-md-12 hover">
    <div class="card-body">
      <h5 class="card-header bg-white fs-6 fw-bolder">Other Areas (expanded search):</h5>

      <div v-if="stillLoading" class="d-flex justify-content-center m-4">
        <PleaseWaitComponent text="Loading data.." />
      </div>

      <div v-else>
        <div v-if="errorOccoured" class="alert alert-light text-danger">
          <i class="bi bi-x-octagon"></i>&nbsp;Sorry, error occoured.
        </div>
        <div v-else>
          <div v-if="u_values.otherStoresArray.length" class="d-grid">
            <span class="p-3 text-dark">
              see <b>{{u_values.otherStoresArray.length}}</b> other
              store{{add_S(u_values.otherStoresArray.length)}} that has your search results.
              <a href="#" @click.prevent="showOtherLocations" class="text-primary fw-bolder">
                click here
              </a>
            </span>
          </div>

          <div v-else class="alert alert-light small text-muted">
            <i class="bi bi-file-earmark-x"></i>&nbsp;No stores.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import PleaseWaitComponent from "@/components/PleaseWaitComponent.vue";

  import { ref, onMounted, reactive, computed, inject } from "vue";
  import axios from "axios";

  export default {
    name: "NearbyStoresComponent",
    // emits: ["ifNearByIsFound"],
    props: {
      nameSearched: String,
    },
    components: {
      PleaseWaitComponent
    },
    setup(props, context) {
      const codeStore = inject('codeStore')
      const baseURL = codeStore.constants.baseURL
      const u_methods = codeStore.methods
      const u_values = codeStore.values


      const stillLoading = ref(true);
      // const resultsIsFound = ref(false);
      const errorOccoured = ref(false);
      const nearByAreaNames = ref([]);


      onMounted(() => {
        u_values.nearByStoresArray = []
        u_values.otherStoresArray = []
        if (u_values.expandedSearchIsActive) {
          stillLoading.value = false;
        }
        else {
          getNearByStores();
        }
      });

      async function getNearByStores() {
        u_values.nearbySearchIsComplete = false
        var objectToSend = {
          DrugName: props.nameSearched,
          Latitude: u_values.userLat,
          Longitude: u_values.userLong,
        };

        const APIstring = baseURL + "MedStoresApi/GetAllStoreAreaByLocation";
        try {
          var { data } = await axios.post(APIstring, objectToSend);
          const response = data.MedicalStoreResponesStatus;
          switch (response) {
            case 400:
              stillLoading.value = false;
              errorOccoured.value = true;
              break;
            case 50:
              stillLoading.value = false;
              // resultsIsFound.value = false;
              break;
            case 200:
              const resultNearBy = u_methods.filterOutStoresWithUnsearchables(data.MedicalStoreResult.NearByAreas)
              const resultOthers = u_methods.filterOutStoresWithUnsearchables(data.MedicalStoreResult.ByAreas)
              stillLoading.value = false;
              // resultsIsFound.value = (resultNearBy.length || resultOthers.length) ? true : false;
              u_values.nearByStoresArray = resultNearBy
              u_values.otherStoresArray = resultOthers
              filterNamesOfAreas();
              populateCurrentShowingDynamically()
              break;
            default:
              stillLoading.value = false;
              errorOccoured.value = true;
              break;
          }
          u_values.nearbySearchIsComplete = true
        } catch (error) {
          stillLoading.value = false;
          errorOccoured.value = true;
        }
      }


      //if main storeist is empty, load the nearby
      const populateCurrentShowingDynamically = () => {
        if (u_values.storeList.length == 0) {
          u_values.storeList = u_values.nearByStoresArray
          u_values.currentLocation = 'nearby areas'
        }
        else if (u_values.nearByStoresArray.length == 0) {
          u_values.storeList = u_values.nearByStoresArray
          u_values.currentLocation = 'other nearby areas'
        }
      }


      const numOfStoresInThisArea = (areaName) => {
        let num = u_values.nearByStoresArray.filter((x) => x.StoreArea == areaName);
        return num.length
      }

      const filterNamesOfAreas = () => {
        let namesArray = u_values.nearByStoresArray.map((x) => x.StoreArea);
        nearByAreaNames.value = u_methods.removeDuplicates(namesArray);
      };

      // const currentShowingIndicator
      function showNearByStores(name) {
        let nearByStoresWithThisName = u_values.nearByStoresArray.filter((x) => x.StoreArea == name);
        u_methods.updateStoreList([nearByStoresWithThisName, name])
        u_methods.moveUp();
      }

      function showOtherLocations() {
        u_methods.updateStoreList([u_values.otherStoresArray, 'other areas(expanded search)'])
        u_methods.moveUp();
      }



      const add_S = (num) => {
        return num == 1 ? "" : "s"
      }

      return {
        stillLoading,
        // resultsIsFound,
        errorOccoured,
        nearByAreaNames,
        showNearByStores,
        showOtherLocations,
        numOfStoresInThisArea,
        add_S,
        u_values
      };
    },
  };
</script>

<style scoped src="@/assets/css/searchResults.css"></style>
<style scoped>
  .nearList:hover {
    background-color: #f0f8fa;
  }

  .nearList {
    padding: 10px;
    border-bottom: 1px solid #f5f5f5;
    padding-bottom: 20px;
    color: #0085a1;
  }

  ::-webkit-scrollbar {
    width: 18px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #181824;
  }
</style>