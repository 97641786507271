import { reactive } from 'vue'
const constants = {
    // baseURL: 'https://45.40.135.165:4438/api/',//test online
    baseURL: 'https://45.40.135.165:4871/api/',//Live online
    // baseURL: 'https://192.168.100.44:45456/api/',//conveyor

    //smartShopURL: 'https://45.40.135.165:7072/api/', //test
    smartShopURL: 'https://matsmartonline.com/api/',//live
    Google_API_KEY: 'AIzaSyBcQXu-vbFB6zJtVKK_6uacHhWbqrtumDI',
}

const values = reactive({
    storeList: [],
    storeListBackUp: [],
    currentLocation: '',
    searchedItem: '',
    showSearchBar: false,

    nearByStoresArray: [],
    otherStoresArray: [],

    userLat: sessionStorage.getItem("allmedUserLat"),
    userLong: sessionStorage.getItem("allmedUserLong"),
    savedSugestions: JSON.parse(sessionStorage.getItem('allmedstoresSuggestions')),
    suggestions: JSON.parse(sessionStorage.getItem('allmedstoresSuggestions')) == null ? [] : JSON.parse(sessionStorage.getItem('allmedstoresSuggestions')),

    expandedSearchIsActive: false,
    nearbyBtnIsClicked: false,
    nearbySearchIsComplete: false,

    storeIdsArray: [],
    timeArray: [],
    serverDateString: '',
    serverTimeString: ''

})

const methods = {
    splitWords: (str) => {
        str = str.replace(/(^\s*)|(\s*$)/gi, "");
        str = str.replace(/[ ]{2,}/gi, " ");
        str = str.replace(/\n /, "\n");
        return str.split(' ');
    },

    removeDuplicates: (array) => {
        return array.filter((a, b) => array.indexOf(a) === b)
        // let unique = [...new Set(array)]; // can remove dupliates too
    },

    saveUserCoordinates(position) {
        values.userLat = position.coords.latitude
        values.userLong = position.coords.longitude
        sessionStorage.setItem("allmedUserLat", position.coords.latitude)
        sessionStorage.setItem("allmedUserLong", position.coords.longitude)
    },

    updateStoreList(data) {
        values.nearbyBtnIsClicked = true
        values.storeList = data[0] == '' ? values.storeListBackUp : data[0]
        values.currentLocation = data[1]
    },

    returnStoreBackUp() {
        methods.updateStoreList(['', 'your area'])
        values.nearbyBtnIsClicked = false
    },

    updateIDArray() {
        let aCreate = values.storeList.map(x => x.StoreId)
        values.storeIdsArray = aCreate
    },

    moveUp() {
        window.scrollTo(0, 0);
        //or this // document.getElementById('domID').scrollIntoView();
    },

    shareStoreData: async (pharm) => {
        let currentUrl = 'https://' + window.location.host; //
        let urlToSend = '/shareStore/' +
            'nme=' + btoa(pharm.StoreName) +
            '&add=' + btoa(pharm.StoreAddress) +
            '&phn=' + btoa(pharm.PhoneNumber) +
            '&drg=' + btoa(values.searchedItem)

        const shareData = {
            title: 'allmedstores',
            text: 'shared pharmacy:',
            url: 'https://allmedstores.com' + urlToSend
        }

        try {
            await navigator.share(shareData)
        } catch (err) {
            console.log(err)
        }
    },

    async shareSite() {
        try {
            await navigator.share({
                title: 'allmedstores.com',
                text: 'Search for drugs/cosmetics from pharmacy stores near you.',
                url: 'https://allmedstores.com'
            })
        } catch (err) {
            console.log(err)
        }
    },

    plural: (num) => {
        return num == 1 ? "" : "s"
    },

    filterOutStoresWithUnsearchables: (data) => {
        let filtered = []
        data.forEach(currentStore => {
            currentStore.medStoresItems.forEach(item => {
                if (item.IsSearchable) {
                    if (filtered.indexOf(currentStore.StoreId) === -1) filtered.push(currentStore);
                }
            })
        });
        let uniqueSet = [...new Set(filtered)];
        return uniqueSet
    },

    numberWithCommas: (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    checkDateForPWAupdate() {
        let today = new Date()
        let savedDate = localStorage.getItem('allmedstoresPWA_Date')
        if (savedDate == null) {
            localStorage.setItem('allmedstoresPWA_Date', today)
            return
        }
        else {
            let nextSevenDays = methods.addDaysToDate(savedDate, 7)
            if (today >= nextSevenDays) {
                localStorage.setItem('allmedstoresPWA_Date', today)
                setTimeout(() => {
                    location.reload()
                }, 1000);
            }
            else {
                const diffTime = Math.abs(today - nextSevenDays);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                console.log(`PWA:${diffDays}dysToRfrsh`);
            }
        }
    },

    addDaysToDate: (date, days) => {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    },

    FirstLetterCaps: (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

}

export default {
    values,
    methods,
    constants
}