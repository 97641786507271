<template>
    <div class="stick-top">
        <NavBarNameComponent navigateToHome="false" />
    </div>
    <div>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-xl-6" style="margin-top: 70px;">
                    <div class="card shadow">
                        <div class="card-header">
                            <div class="text-center text-muted">
                                <i class="bi bi-link"></i> shared link
                            </div>
                        </div>
                        <div class="card-body">
                            <h2 class="post-title fs-4 fw-bold"> {{details.name}}</h2>
                            <p> <small>{{details.address}}</small></p>
                            <hr>
                            <p> <b>searched:</b> <small><mark>{{details.drug}}</mark></small></p>
                            <p class="card-text float-end my-4">
                                <a :href="'tel:' + details.phone" class="callBtn small fw-bold">
                                    <small><i class="bi small bi-telephone-fill small"></i> call now</small>
                                </a>
                            </p>
                        </div>
                    </div>

                    <div class="mt-5 text-center fw-bold small">
                        Search this item around your location?<span @click="searchDrug" class="actionBtn small">Click
                            here</span>

                    </div>
                    <div class="text-center fst-italic"><small> <b>Note:</b> Allow LOCATION access on prompt.</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footerComponent />
</template>

<script>
    import { computed, reactive } from 'vue'
    import NavBarNameComponent from "@/components/NavBarNameComponent.vue";
    import footerComponent from "@/components/footerComponent.vue";
    import { useRouter, useRoute } from "vue-router";
    export default {
        components: { NavBarNameComponent, footerComponent },
        props: {
            storeName: String,
            storeAddress: String,
            storePhone: String,
            drugName: String
        },
        setup(props) {
            const router = useRouter();

            const details = reactive({
                name: atob(props.storeName),
                address: atob(props.storeAddress),
                phone: atob(props.storePhone),
                drug: atob(props.drugName),
            })

            function searchDrug() {
                router.push({
                    name: "results",
                    params: {
                        toSearch: details.drug
                    },
                });
            }


            return { details, searchDrug }
        }
    }
</script>

<style scoped src="@/assets/css/searchResults.css"></style>
<style scoped>
    .callBtn {
        min-width: 1.8em;
        padding: 0.2em 1em 0.5em 1em !important;
        margin-left: 0.1em;
        background-color: #edad18;
        color: #fff !important;
        cursor: pointer;
        border-radius: 5px;
    }

    .callBtn:hover {
        background-color: #facd6d;
    }

    .actionBtn {
        min-width: 1.8em;
        padding: 0em 0.21em !important;
        margin-left: 0.21em;
        /* background-color: #d4f1f5; */
        color: #0085a1 !important;
        cursor: pointer;
        border-radius: 5px;
        font-weight: bold;
    }

    .actionBtn:hover {
        background-color: #55def7;
    }

    .card {
        border-bottom: 2px solid #edad18;
    }
</style>