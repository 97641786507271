<template>
    <div class="sticky-top">
        <NavBarNameComponent navigateToHome="true" />

        <!-- for desktop -->
        <!-- <div v-show="!searchError" class="container-fluid bg-white search-pad desktop-view shadow-sm">
            <div style="padding: 0px 20px 5px 20px">
                <div class="row">
                    <div class="col-md-1" style="margin-top: 29px;">
                        <div class="pretty p-default">
                            <input type="checkbox" v-model="advancedChecked" />
                            <div class="state p-primary">
                                <label class="small"><b>expand search</b></label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1"></div>
                    <transition name="slide-fade">
                        <div v-show="advancedChecked" class="col-md-2 mt-3">
                            <vue-select @blur="findAreasByState" :disabled="statesLoaded" class="dropdown"
                                :placeholder="statesPlaceHolder" :clear-on-select="true" v-model="selectedState"
                                :searchable="true" :options="statesDropdown" close-on-select>
                            </vue-select>
                        </div>
                    </transition>

                    <transition name="slide-fade">
                        <div v-show="advancedChecked" class="col-md-3 mt-3">
                            <vue-select :disabled="areasLoaded" class="dropdown" :placeholder="areaPlaceHolder"
                                :clear-on-select="true" v-model="selectedArea" :searchable="true"
                                :options="areasDropDown" close-on-select>
                            </vue-select>
                            <span v-if="areaIsLoading">
                                <PleaseWaitComponent />
                            </span>
                        </div>
                    </transition>


                    <div class="col-md-5 mt-3">
                        <div class="row d-flex">
                            <form @submit.prevent="searchOnClick">
                                <div class="form my-0">
                                    <input v-model.trim="nameSearched" type="text" style="border: 2px solid #d4d2d2;"
                                        class="form-control form-input radius" placeholder="search here" />
                                    <span class="left-pan"><i @click.prevent="searchOnClick"
                                            class="bi bi-search btn p-0 m-0"></i></span>
                                </div>
                            </form>
                        </div>
                    </div>


                </div>
            </div>
        </div> -->

        <!-- for mobile screen -->
        <div v-show="!searchError" @click="u_values.showSearchBar = !u_values.showSearchBar"
            class="container-fluid bg-white mobile-view shadow-sm">
            <div class="col-md-2">
                <div class="d-flex justify-content-center">
                    <div class="p-3"><i class="bi bi-search"></i> search more..</div>
                </div>
            </div>
        </div>


        <!-- modal for search -->
        <transition name="slide-fade">
            <div v-show="u_values.showSearchBar" class="modal" tabindex="-1">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="my-2 mx-4">
                            <div class="mt-2">
                                <span @click="closeSearchBar" class="float-end modalCloser"><i class="bi bi-x-lg"></i>
                                </span>
                            </div>
                        </div>

                        <div class="modal-body">
                            <div style="padding: 0px 10px 5px 10px">
                                <div class="row">
                                    <span v-show="advancedChecked" class="mb-3 fw-bold">Expand your search:</span>
                                    <transition name="slide-fade">
                                        <div v-show="advancedChecked" class="col-md-12">
                                            <vue-select @blur="findAreasByState" :disabled="statesLoaded"
                                                class="dropdown" :placeholder="statesPlaceHolder"
                                                :clear-on-select="true" v-model="selectedState" :searchable="true"
                                                :options="statesDropdown" close-on-select>
                                            </vue-select>
                                        </div>
                                    </transition>
                                    <transition name="slide-fade">
                                        <div v-show="advancedChecked" class="col-md-12 my-3">
                                            <vue-select :disabled="areasLoaded" class="dropdown"
                                                :placeholder="areaPlaceHolder" :clear-on-select="true"
                                                v-model="selectedArea" :searchable="true" :options="areasDropDown"
                                                close-on-select>
                                            </vue-select>
                                            <span v-if="areaIsLoading">
                                                <PleaseWaitComponent />
                                            </span>
                                        </div>
                                    </transition>

                                    <div class="col-md-12">
                                        <input v-model.trim="nameSearched" type="text"
                                            style="border: 2px solid #d4d2d2;" class="form-control form-input radius"
                                            placeholder="search here" />
                                    </div>
                                    <div class="col-md-12 mt-2">
                                        <div class="pretty p-default p-round "
                                            style="margin-top: 15px; font-size: 14px;">
                                            <input type="checkbox" v-model="advancedChecked" />
                                            <div class="state p-primary">
                                                <label v-if="!advancedChecked" class="small"><b>expand
                                                        search ?</b></label>
                                                <label v-else class="small"><b>expand
                                                        search<i class="bi bi-check-circle"></i></b></label>
                                            </div>
                                        </div>
                                        <a href="#" @click.prevent="searchOnClick"
                                            class="float-end  fw-bolder searchBtn">SEARCH
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>

    <div v-if="locationError">
        <LocationDeniedComponent />

        <!-- modal -->
        <div v-show="locationDialogOpen" class="modal" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title text-danger fw-bold">Location denied.
                        </h4>
                    </div>
                    <div class="modal-body">
                        <p><i>We can not access your location, <b>reasons may be:</b></i></p>
                        <p>
                        <ul>
                            <li>your location is turned off.</li>
                            <li>your browser does not support <b>Geolocation</b>, (please try another
                                browser).</li>
                        </ul>
                        </p>
                        <span>
                            You may also try <b>expand search</b> option to search by states or areas.
                        </span>
                    </div>
                    <div class="m-4 d-flex justify-content-between">
                        <div class="fw-bold">
                            <router-link to="/" style="color: #0085a1;">TRY
                                AGAIN</router-link>
                        </div>
                        <div class="fw-bold">
                            <router-link to="/"></router-link>
                            <a @click.prevent="useExpandedSearch" href="" style="color: #0085a1;">EXPAND SEARCH</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-else>
        <div v-if="apiIsStillLoading" class="row justify-content-center" style="margin-top: 200px;">
            <loader object="#0085a1" objectbg="#fff" size="3" speed="2" name="spinning"></loader>
        </div>
        <div v-else class="px-4 px-lg-5">
            <div v-if="searchError">
                <SearchErrorComponent />
            </div>
            <div v-else>
                <div class="row gx-4 gx-lg-5">
                    <transition name="listSlide">
                        <div v-if="u_values.storeList.length" :key="u_values.storeList" class="container col-md-7">
                            <StoreListComponent :searchedItem="nameSearched" />
                        </div>
                    </transition>
                    <div v-if="!(u_values.storeList.length)" class="container col-md-7">
                        <NoSearchResultsComponent :itemSearched="nameSearched" />
                    </div>
                    <div class="col-md-5 mt-5">
                        <NearbyStoresComponent :nameSearched="nameSearched" /> <br />
                        <ContactUsComponent />
                    </div>
                </div>
            </div>

        </div>
    </div>

    <footerComponent v-if="!apiIsStillLoading" />
</template>

<script>
    import NoSearchResultsComponent from "@/components/NoSearchResultsComponent.vue";
    import NavBarNameComponent from "@/components/NavBarNameComponent.vue";
    import LocationDeniedComponent from "@/components/LocationDeniedComponent.vue";
    import SearchErrorComponent from "@/components/SearchErrorComponent.vue";
    import NearbyStoresComponent from "@/components/NearbyStoresComponent.vue";
    import PleaseWaitComponent from "@/components/PleaseWaitComponent.vue";
    import StoreListComponent from "@/components/StoreListComponent.vue";
    import footerComponent from "@/components/footerComponent.vue";
    import ContactUsComponent from "@/components/ContactUsComponent.vue";

    import { computed, ref, onMounted, onUnmounted, watch, reactive, toRefs, inject } from "vue";
    import { useRouter, useRoute } from "vue-router";

    import VueSelect from "vue-next-select";
    import "vue-next-select/dist/index.min.css";

    import axios from "axios";
    import { useToast } from "vue-toastification";

    export default {
        components: {
            VueSelect,
            StoreListComponent,
            NoSearchResultsComponent,
            NavBarNameComponent,
            LocationDeniedComponent,
            NearbyStoresComponent,
            footerComponent,
            ContactUsComponent,
            SearchErrorComponent,
            PleaseWaitComponent
        },
        name: "SearchResults",
        props: {
            toSearch: String,
        },
        setup(props) {
            const codeStore = inject('codeStore')
            const baseURL = codeStore.constants.baseURL
            const u_methods = codeStore.methods
            const u_values = codeStore.values
            const toast = useToast();
            u_values.searchedItem = props.toSearch




            //ROUTER OBJECTS ######################
            const router = useRouter();
            const route = useRoute();
            //ROUTER OBJECTS ######################


            const determinantObject = reactive({
                locationError: false,
                apiIsStillLoading: true,
                searchError: true,
                locationDialogOpen: false
            });

            const searchParametersObject = reactive({
                nameSearched: props.toSearch,
                // showMobileSearchBar: false,
            });

            const statesAndAreasObject = reactive({
                statesPlaceHolder: 'fetching states...',
                statesDropdown: [],
                selectedState: null,
                statesLoaded: computed(() => {
                    return (statesAndAreasObject.statesDropdown.length > 0) ? false : true
                }),

                areaPlaceHolder: 'area/location:',
                areasDropDown: [],
                selectedArea: null,
                areaIsLoading: false,
                areasLoaded: computed(() => {
                    return (statesAndAreasObject.areasDropDown.length > 0) ? false : true
                }),

                advancedChecked: false
            })

            onMounted(() => {
                u_methods.checkDateForPWAupdate()
                getStoresByProximity();
                loadStatesDropdown()
            });

            function getStoresByProximity() {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        u_methods.saveUserCoordinates(position)
                        getStoresByProximityNEXT();
                    },
                    (error) => {
                        if (error.code == 1) {
                            showLocationError()
                        }
                        else if (error.code == 2) {
                            determinantObject.apiIsStillLoading = false;
                            determinantObject.searchError = true;
                        }
                    },
                    // { enableHighAccuracy: false, timeout: 5000 }
                );
            }

            function showLocationError() {
                determinantObject.searchError = false;
                determinantObject.locationError = true;
                determinantObject.locationDialogOpen = true
                return
            }

            function useExpandedSearch() {
                determinantObject.locationDialogOpen = false
                // searchParametersObject.showMobileSearchBar = true
                u_values.showSearchBar = true
                statesAndAreasObject.advancedChecked = true
            }

            async function getStoresByProximityNEXT() {
                u_values.expandedSearchIsActive = false;
                determinantObject.locationError = false;

                u_values.storeList = []
                u_values.storeListBackUp = []
                determinantObject.apiIsStillLoading = true
                determinantObject.storesHaveBeenFound = false
                var objectToSend = {
                    DrugName: searchParametersObject.nameSearched,
                    Latitude: u_values.userLat,
                    Longitude: u_values.userLong,
                };

                const APIstring = baseURL + "MedStoresApi/GetAllStoreDetailsByLocation";
                try {
                    var { data } = await axios.post(APIstring, objectToSend, {
                        timeout: 40000,
                    });
                    const response = data.MedicalStoreResponesStatus;
                    const result = data.MedicalStoreResult;
                    switch (response) {
                        case 400:
                            determinantObject.apiIsStillLoading = false;
                            determinantObject.searchError = true;
                            break;
                        case 50:
                            determinantObject.apiIsStillLoading = false;
                            determinantObject.searchError = false;
                            break;
                        case 200:
                            determinantObject.apiIsStillLoading = false;
                            determinantObject.searchError = false;
                            // u_methods.saveStore(result)

                            u_values.storeList = u_methods.filterOutStoresWithUnsearchables(result)
                            u_values.storeListBackUp = u_values.storeList
                            u_values.currentLocation = 'your area'
                            break;
                        default:
                            determinantObject.apiIsStillLoading = false
                            determinantObject.searchError = true
                            break;
                    }
                } catch (error) {
                    determinantObject.apiIsStillLoading = false;
                    determinantObject.searchError = true;
                }
            }

            async function loadStatesDropdown() {
                const APIstring = baseURL + "MedStoresApi/GetAllStates";
                try {
                    var { data } = await axios.get(APIstring);
                    let statesArray = data.MedicalStoreResult
                    let filteredArray = ((statesArray == null) || (statesArray == undefined)) ? [] : statesArray.map((x) => x.State)
                    statesAndAreasObject.statesDropdown.push('All')
                    filteredArray.forEach(element => {
                        statesAndAreasObject.statesDropdown.push(element)
                    });
                    statesAndAreasObject.statesPlaceHolder = 'select state:'

                } catch (error) {
                }
            }

            function findAreasByState() {
                statesAndAreasObject.areasDropDown = []
                statesAndAreasObject.selectedArea = null
                var nameToSend = statesAndAreasObject.selectedState
                if (nameToSend != null) {
                    if (nameToSend == 'All') loadAreasDropdown('*')
                    else loadAreasDropdown(nameToSend)
                }
            }

            async function loadAreasDropdown(stateName) {
                let textToshow = stateName == '*' ? '' : ('in ' + stateName)
                statesAndAreasObject.areaPlaceHolder = '...fetching all areas ' + textToshow
                statesAndAreasObject.areaIsLoading = true
                const APIstring = baseURL + "MedStoresApi/GetAreasByState?State=" + stateName;
                try {
                    var { data } = await axios.get(APIstring);
                    let areaArray = data.MedicalStoreResult
                    let filteredArray = ((areaArray == null) || (areaArray == undefined)) ? [] : areaArray.map((x) => x.Area)
                    statesAndAreasObject.areasDropDown.push('All')
                    filteredArray.forEach(element => {
                        statesAndAreasObject.areasDropDown.push(element)
                    });
                    statesAndAreasObject.areaIsLoading = false
                    if (areaArray.length) statesAndAreasObject.areaPlaceHolder = 'select area:'
                    if (stateName == '*') {
                        statesAndAreasObject.selectedArea = statesAndAreasObject.areasDropDown[0]
                    }
                } catch (error) {
                }
            }

            function searchOnClick() {
                var lengthOfWords = searchParametersObject.nameSearched.length
                // if (lengthOfWords) {
                if (lengthOfWords < 3) {
                    toast.info("Search KEYWORD must not be less than 3 characters", {
                        timeout: 5000,
                        hideProgressBar: true,
                        position: 'top-right'
                    });
                }
                else searchOnClickNEXT()
                // }
            }

            //start onclick
            function searchOnClickNEXT() {
                if (!statesAndAreasObject.advancedChecked) {
                    // searchParametersObject.showMobileSearchBar = false
                    u_values.showSearchBar = false
                    // getStoresByProximity();
                    router.push({
                        name: "results",
                        params: {
                            toSearch: searchParametersObject.nameSearched
                        },
                    });
                }
                else {
                    proceedWithAdvancedSearch()
                }
            }

            function proceedWithAdvancedSearch() {
                let state = statesAndAreasObject.selectedState
                let area = statesAndAreasObject.selectedArea
                if (state == null) {
                    toast.info("For expanded search, you should select state from the dropdown", {
                        timeout: 3000,
                        hideProgressBar: true,
                        position: 'top-right'
                    });
                    return false
                }
                else if (area == null) {
                    let space = (state == 'All') ? '' : 'in ' + state
                    toast.info("select an area " + space, {
                        timeout: 3000,
                        hideProgressBar: true,
                        position: 'top-right'
                    });
                    return false
                }
                else {
                    determinantObject.searchError = false;
                    determinantObject.locationError = false;
                    u_values.expandedSearchIsActive = true;
                    statesAndAreasObject.advancedChecked = false
                    let State = (state == 'All') ? '*' : state
                    let Area = (area == 'All') ? '*' : area
                    getStoresByStateAndAreas(State, Area)
                }
            }

            async function getStoresByStateAndAreas(State, Area) {
                determinantObject.apiIsStillLoading = true
                // searchParametersObject.showMobileSearchBar = false
                u_values.showSearchBar = false

                const objectToSend = {
                    State: State,
                    Area: Area,
                    DrugName: searchParametersObject.nameSearched,
                    Latitude: u_values.userLat,
                    Longitude: u_values.userLong
                }
                const APIstring = baseURL + "MedStoresApi/GetAllDrugsByStateAndAreas";
                try {
                    var { data } = await axios.post(APIstring, objectToSend);
                    if (data.MedicalStoreResponesStatus == 200) {
                        u_values.storeList = u_methods.filterOutStoresWithUnsearchables(data.MedicalStoreResult.Option1)
                        u_values.currentLocation = 'an expanded search'
                        u_values.searchedItem = objectToSend.DrugName
                        u_values.nearByStoresArray = []
                        u_values.otherStoresArray = []
                        u_values.nearbyBtnIsClicked = false
                        u_values.expandedSearchIsActive = true;
                        determinantObject.apiIsStillLoading = false
                    }
                    else {
                        u_values.storeList = [];
                        u_values.nearByStoresArray = []
                        u_values.otherStoresArray = []
                        determinantObject.apiIsStillLoading = false
                    }

                } catch (error) {
                }
            }

            watch(() => route.params.toSearch, () => {
                getStoresByProximity()
            });

            function closeSearchBar() {
                u_values.showSearchBar = !u_values.showSearchBar
                statesAndAreasObject.advancedChecked = false
            }

            //###############FUNCTIONS FROM CHILD COMPONENTS ###################

            // const resultIsfoundInNearBy = ref()
            // function ifNearByIsFound(resultsFound) {
            //     resultIsfoundInNearBy.value = resultsFound
            // }

            //###############END OF FUNCTIONS FROM CHILD COMPONENTS ###################

            return {
                searchOnClick: searchOnClick,
                ...toRefs(determinantObject),
                ...toRefs(searchParametersObject),
                ...toRefs(statesAndAreasObject),
                // ifNearByIsFound: ifNearByIsFound,
                // resultIsfoundInNearBy: resultIsfoundInNearBy,
                findAreasByState: findAreasByState,
                u_values, useExpandedSearch,
                closeSearchBar
            };
        },
    };
</script>

<style scoped src="@/assets/css/searchResults.css"></style>
<style scoped>
    .slide-fade-enter-active {
        transition: all 0.3s ease-out;
    }

    /* .slide-fade-leave-active {
        transition: all 0.2s ease-out;
    } */

    .slide-fade-enter-from,
    .slide-fade-leave-to {
        transform: translateY(-20px);
        opacity: 0;
    }

    .listSlide-enter-active {
        transition: all .3s ease;
    }

    .listSlide-enter-from,
    .listSlide-leave-to {
        transform: translateX(-20px);
        opacity: 0;
    }


    .dropdown {
        width: 100%;
        border-radius: 10px;
        height: 40px;
        padding: 5px 10px;
        border: 2px solid #d4d2d2;
    }

    .modal {
        display: inline;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .modalCloser {
        cursor: pointer;
    }

    .modalCloser:hover {
        color: #0085a1;
    }

    .searchBtn {
        color: #0085a1;
        margin-top: 8px;
    }

    .searchBtn:hover {
        color: #285058;
    }
</style>